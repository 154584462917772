<template>
  <div class="content_body Project" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form :inline="true" size="small" @keyup.enter.native="getSearchResult">
            <el-form-item label="项目名称">
              <el-input v-model="searchKey" @clear="getSearchResult" clearable placeholder="输入项目名称或别名"></el-input>
            </el-form-item>
            <el-form-item label="项目分类">
              <el-cascader @change="getSearchResult" @clear="getSearchResult" :options="projectClassifyList" :show-all-levels="true" clearable filterable :props="cascaderProps" v-model="classifyId" placeholder="请选择项目分类"></el-cascader>
            </el-form-item>
            <el-form-item label="项目品牌">
              <el-select v-model="ProjectBrandID " clearable filterable placeholder="请选择项目品牌" :default-first-option="true" @change="getSearchResult" @clear="getSearchResult">
                <el-option v-for="item in ProjectBrandList" :key="item.ID" :label="item.Name" :value="item.ID">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否上架">
              <el-select @change="getSearchResult" @clear="getSearchResult" v-model="IsAllowSell" placeholder="请选择是否上架" clearable>
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="getSearchResult" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button v-if="isProjectNew" type="primary" size="small" @click="showAddDialog" v-prevent-click>新增</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="martp_10">
      <el-table size="small" ref="multipleTable" :data="projectList">
        <el-table-column prop="Name" label="项目名称"></el-table-column>
        <el-table-column prop="Alias" label="项目别名"></el-table-column>
        <el-table-column prop="ProjectCategoryName" label="项目分类"></el-table-column>
        <el-table-column prop="ProjectBrandName" label="项目品牌"></el-table-column>
        <el-table-column prop="Price" label="销售价格">
          <template slot-scope="scope">￥{{scope.row.Price | NumFormat}}</template>
        </el-table-column>
        <el-table-column label="是否允许消耗">
          <template slot-scope="scope">{{scope.row.IsAllowConsume?'是':'否'}}</template>
        </el-table-column>
        <el-table-column label="是否可赠送">
          <template slot-scope="scope">{{scope.row.IsAllowLargess?'是':'否'}}</template>
        </el-table-column>
        <el-table-column label="是否上架">
          <template slot-scope="scope">{{scope.row.IsAllowSell?'是':'否'}}</template>
        </el-table-column>
        <el-table-column label="操作" width="145">
          <template slot-scope="scope">
            <el-button v-if="isProjectEdit" type="primary" size="small" @click="showEditDialog(scope.row,'edit')" v-prevent-click>编辑</el-button>
            <el-button type="primary" size="small" @click="showEditDialog(scope.row,'show')" v-prevent-click>详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pad_15 dis_flex flex_x_between" v-if="false">
        <div>
          <el-dropdown trigger="click" @command="dropdownClick" size="small">
            <el-button type="primary" size="small" v-prevent-click>
              批量设置
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1">分类</el-dropdown-item>
              <el-dropdown-item command="2">是否上架</el-dropdown-item>
              <el-dropdown-item command="3">是否允许消耗</el-dropdown-item>
              <el-dropdown-item command="4">是否可以赠送</el-dropdown-item>
              <el-dropdown-item command="5">销售范围</el-dropdown-item>
              <el-dropdown-item command="6">消耗范围</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="text_right pad_15">
        <el-pagination background v-if="paginations.total > 0" @current-change="handleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
      </div>
    </div>
    <!--增加、编辑弹出框-->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :close-on-click-modal="false" width="950px">
      <el-tabs v-model="activeName">
        <el-tab-pane label="基本信息" name="1">
          <div class="pad_15">
            <el-form :model="ruleForm_add" :rules="rules_add" ref="ruleForm_add" label-width="120px" size="small">
              <el-col :span="12">
                <el-form-item label="项目名称" prop="Name">
                  <el-input v-model="ruleForm_add.Name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="项目别名">
                  <el-input v-model="ruleForm_add.Alias"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="项目耗时" prop="TreatTime">
                  <el-input v-model="ruleForm_add.TreatTime" type="number">
                    <template slot="append">分</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="销售价格" prop="Price">
                  <el-input v-model="ruleForm_add.Price" v-positiveNumber type="number">
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="项目分类" prop="ProjectCategoryID">
                  <el-cascader clearable style="position:absolute" :options="projectClassifyList" :show-all-levels="true" filterable :props="cascaderProps" v-model="ruleForm_add.ProjectCategoryID"></el-cascader>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="项目品牌">
                  <el-select v-model="ruleForm_add.ProjectBrandID " clearable filterable placeholder="请选择项目品牌" :default-first-option="true">
                    <el-option v-for="item in ProjectBrandList" :key="item.ID" :label="item.Name" :value="item.ID">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否上架" prop="IsAllowSell">
                  <el-radio-group v-model="ruleForm_add.IsAllowSell">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否允许消耗" prop="IsAllowConsume">
                  <el-radio-group v-model="ruleForm_add.IsAllowConsume">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否可赠送" prop="IsAllowLargess">
                  <el-radio-group v-model="ruleForm_add.IsAllowLargess">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="备注信息">
                  <el-input type="textarea" :rows="4" placeholder="请输入备注信息" v-model="ruleForm_add.Remark"></el-input>
                </el-form-item>
              </el-col>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="详细信息" name="4">
          <div class="dis_flex flex_dir_row">
            <goodsDetail ref="goodsDetail" :Memo="ruleForm_add.Memo" :ImageList="ruleForm_add.ImageList" :OriginalText="ruleForm_add.OriginalText" :Price="ruleForm_add.Price" :Name="ruleForm_add.Name" GoodsType="项目" @OriginalText_Change="OriginalText_Change" @Memo_change="Memo_change" @ImageListChange="ImageListChange">
              <el-row slot="info">
                <el-col :span="24" class="padlt_10 padrt_10" style="height:40px;line-height:40px">商品信息</el-col>
                <el-col :span="24" class="back_f8 pad_10">
                  <el-col :span="24">适用门店:适用共{{ruleForm_add.ApplyNum}}家门店 <span style="color:#FF8D66">查看门店</span></el-col>
                </el-col>
              </el-row>
            </goodsDetail>
          </div>

        </el-tab-pane>
        <el-tab-pane label="销售范围" name="2">
          <span slot="label">
            销售范围
            <el-popover placement="top-start" width="200" trigger="hover">
              <p>适用于同级所有节点，则只需勾选父节点。</p>
              <p>比如：适用于所有节点，只需勾选“顶级/第一个”节点。</p>
              <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
            </el-popover>
          </span>
          <el-scrollbar class="el-scrollbar_height">
            <el-tree ref="treeSale" :expand-on-click-node="false" :check-on-click-node="true" :check-strictly="true" :data="salesScopeData" show-checkbox node-key="ID" :default-checked-keys="defaultCheckedKeysSale" :default-expanded-keys="defaultExpandedKeysSale" :props="defaultProps"></el-tree>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane label name="3">
          <span slot="label">
            消耗范围
            <el-popover placement="top-start" width="200" trigger="hover">
              <p>适用于同级所有节点，则只需勾选父节点。</p>
              <p>比如：适用于所有节点，只需勾选“顶级/第一个”节点。</p>
              <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
            </el-popover>
          </span>
          <el-scrollbar class="el-scrollbar_height">
            <el-tree ref="treeConsume" :expand-on-click-node="false" :check-on-click-node="true" :check-strictly="true" :data="consumptionData" show-checkbox node-key="ID" :default-checked-keys="defaultCheckedKeysConsume" :default-expanded-keys="defaultExpandedKeysConsume" :props="defaultProps"></el-tree>
          </el-scrollbar>
        </el-tab-pane>
      </el-tabs>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small" v-prevent-click>取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm_add')" size="small" v-prevent-click :loading="updateLoading">保 存</el-button>
      </div>
    </el-dialog>
    <!-- 查看弹出框 -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialogVisible" width="950px">
      <el-tabs v-model="activeName">
        <el-tab-pane label="基本信息" name="1">
          <div class="pad_15">
            <el-form class="showDialog" label-width="150px" size="small">
              <el-col :span="12">
                <el-form-item label="项目名称：">
                  <span>{{ruleForm_add.Name}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="项目别名：">
                  <span>{{ruleForm_add.Alias}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="项目耗时：">
                  <span>{{ruleForm_add.TreatTime}}分</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="销售价格：">
                  <span>{{ruleForm_add.Price}}元</span>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="项目分类：">
                  <span>{{ProjectCategory}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="项目品牌：">
                  <span>{{ProjectBrand}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否上架：">
                  <span v-if="ruleForm_add.IsAllowSell">是</span>
                  <span v-else>否</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否允许消耗：">
                  <span v-if="ruleForm_add.IsAllowConsume">是</span>
                  <span v-else>否</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否可赠送：">
                  <span v-if="ruleForm_add.IsAllowLargess">是</span>
                  <span v-else>否</span>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="备注信息：">
                  <span>{{ruleForm_add.Remark}}</span>
                </el-form-item>
              </el-col>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="详细信息" name="2">
          <div class="dis_flex flex_x_center">
            <showGoodsDetail ref="ShowGoodsDetail" :Memo="ruleForm_add.Memo" :ImageList="ruleForm_add.ImageList" :OriginalText="ruleForm_add.OriginalText" :Price="ruleForm_add.Price" :Name="ruleForm_add.Name" GoodsType="项目">
              <el-row slot="info">
                <el-col :span="24" class="padlt_10 padrt_10" style="height:40px;line-height:40px">商品信息</el-col>
                <el-col :span="24" class="back_f8 pad_10">
                  <el-col :span="24">适用门店:适用共{{ruleForm_add.ApplyNum}}家门店 <span style="color:#FF8D66">查看门店</span></el-col>
                </el-col>
              </el-row>
            </showGoodsDetail>
          </div>

        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <!--批量设置弹出框-->
    <el-dialog title="设置" :visible.sync="setBatchDialogVisible" width="30%">
      <el-form :model="setBatchForm" :rules="setBatchRules" ref="setBatchForm" label-width="120px" size="small">
        <el-form-item label="分类" v-if="selectType == '1'">
          <el-cascader :options="entityList" :show-all-levels="false" clearable :props="setBatchCascaderProps" v-model="setBatchForm.EntityID"></el-cascader>
        </el-form-item>
        <el-form-item label="是否上架" prop="isUpperShelf" v-if="selectType == '2'">
          <el-radio-group v-model="setBatchForm.isUpperShelf">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否允许消耗" prop="isConsumption" v-if="selectType == '3'">
          <el-radio-group v-model="setBatchForm.isConsumption">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否允许赠送" prop="isGift" v-if="selectType == '4'">
          <el-radio-group v-model="setBatchForm.isConsumption">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer border_top">
        <el-button @click="setBatchDialogVisible = false" size="small" v-prevent-click>取 消</el-button>
        <el-button type="primary" @click="setBatchDialogVisible = false" size="small" v-prevent-click>保 存</el-button>
      </div>
    </el-dialog>
    <!--添加子分类弹出框-->
    <el-dialog title="子分类" :visible.sync="addProjectClassifyDiaState" width="30%">
      <el-form :model="addProjectClassifyForm" :rules="addProjectClassifyForm_rules" ref="addProjectClassifyForm" label-width="120px" size="small">
        <el-form-item label="上级分类：">
          <el-row>
            <el-col :span="12">
              <el-cascader :options="projectClassifyList" :show-all-levels="false" clearable :props="cascaderProps" v-model="addProjectClassifyForm.superiorClassify"></el-cascader>
            </el-col>
            <el-col :span="10">
              <span class="color_primary marlt_15" @click="addSuperiorClassification">添加</span>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="分类名称：" prop="classifyName">
          <el-input v-model="addProjectClassifyForm.classifyName" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addProjectClassifyDiaState = false" size="small" v-prevent-click>取 消</el-button>
        <el-button type="primary" size="small" @click="submitSuperiorClassify('addProjectClassifyForm','1')" v-prevent-click>确 定</el-button>
      </div>
    </el-dialog>
    <!--添加上级分类弹出框-->
    <el-dialog title="分类" :visible.sync="addSubClassificationDiaState" width="30%">
      <el-form :model="addSubClassificationForm" :rules="addSubClassificationForm_rules" ref="addSubClassificationForm" label-width="120px" size="small">
        <el-form-item label="分类名称：" prop="classificationName">
          <el-input v-model="addSubClassificationForm.classificationName" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addSubClassificationDiaState = false" size="small" v-prevent-click>取 消</el-button>
        <el-button type="primary" size="small" @click="submitSuperiorClassify('addSubClassificationForm','2')" v-prevent-click>确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/iBeauty/Goods/project";
import ProjectBrandAPI from "@/api/iBeauty/Basic/projectBrand";
import permission from "@/components/js/permission.js";
import goodsDetail from "../Goods/Components/GoodsDetail.vue";
import showGoodsDetail from "../Goods/Components/ShowGoodsDetail.vue";
import utils from "@/components/js/utils.js";
var Enumerable = require("linq");

export default {
  name: "Project",
  /**  引入的组件  */
  components: {
    goodsDetail,
    showGoodsDetail,
  },
  data() {
    return {
      updateLoading: false,
      loading: false, // 加载状态
      addProjectClassifyDiaState: false, // 添加分类弹出框状态
      addSubClassificationDiaState: false, // 添加上级分类弹出框状态
      setBatchDialogVisible: false, // 批量设置弹出框状态
      dialogVisible: false, // 增加、编辑弹出框状态
      showDialogVisible: false, //查看弹出层
      searchKey: "", // 搜索框值
      IsAllowSell: true, // 是否上架
      projectClassifyList: [], // 项目分类
      projectList: [], // 项目列表信息
      classifyId: null, // 所选中的分类Id
      openNumber: "", // 判断是添加子分类还是上级分类
      ProjectBrandID: "",
      ParentID: "", // 添加分类所传参数
      Name: "",
      whichDialog: "", // 弹出框为添加还是为编辑
      dialogTitle: "", //  增加、编辑弹出框标题,
      ID: "", // 所编辑的信息ID,
      activeName: "1", // 所选中的标签页
      salesScopeData: [], // 销售范围数据
      ProjectSaleEntity: [], // 项目销售范围-参数
      consumptionData: [], // 消耗范围
      ProjectConsumptionEntity: [], // 消耗范围-参数
      selectType: "",
      entityList: [],
      defaultCheckedKeysSale: [],
      defaultExpandedKeysSale: [1],
      defaultCheckedKeysConsume: [],
      defaultExpandedKeysConsume: [1],
      defaultProps: {
        children: "Child",
        label: "EntityName",
      }, // 销售范围选择配置项
      cascaderProps: {
        value: "ID",
        label: "Name",
        children: "Child",
        emitPath: false,
        expandTrigger: "hover",
      }, // 级联选择器配置项
      setBatchCascaderProps: {
        value: "id",
        label: "label",
        children: "children",
      }, // 批量设置级联选择器配置项
      ruleForm_add: {
        Name: "",
        Alias: "",
        specification: "",
        unit: "",
        Price: "",
        IsAllowSell: "",
        ProjectCategoryID: null,
        IsAllowLargess: "",
        TreatTime: "",
        IsAllowConsume: "",
        ProjectBrandID: "",
        Remark: "",
        Memo: "",
        OriginalText: "",
        ImageList: [],
      }, // 增加、编辑表单信息
      rules_add: {
        Name: [{ required: true, message: "请输入项目名称", trigger: "blur" }],
        Price: [{ required: true, message: "请输入销售价格", trigger: "blur" }],
        IsAllowSell: [
          { required: true, message: "请选择是否可以上架", trigger: "change" },
        ],
        ProjectCategoryID: [
          { required: true, message: "请选择项目分类", trigger: "change" },
        ],
        IsAllowLargess: [
          { required: true, message: "请选择是否可以赠送", trigger: "change" },
        ],
        IsAllowConsume: [
          { required: true, message: "请选额是否允许消耗", trigger: "change" },
        ],
        TreatTime: [
          { required: true, message: "请选择项目耗时", trigger: "change" },
        ],
      }, // 增加、编辑表单信息
      setBatchForm: {
        EntityID: "",
        isUpperShelf: "",
        isConsumption: "",
        isGift: "",
      }, // 批量设置 表单数据
      setBatchRules: {
        isUpperShelf: [
          { required: true, message: "请选择是否上架", trigger: "change" },
        ],
        isConsumption: [
          { required: true, message: "请选择是否允许消耗", trigger: "change" },
        ],
        isGift: [
          { required: true, message: "请选择是否允许赠送", trigger: "change" },
        ],
      }, // 批量设置 表单数据验证
      addSubClassificationForm: {
        classificationName: "",
      }, // 添加上级分类表单
      addSubClassificationForm_rules: {
        classificationName: [
          { required: true, message: "请输入项目分类名称", trigger: "blur" },
        ],
      }, // 添加上级分类表单验证
      addProjectClassifyForm: {
        superiorClassify: null,
        classifyName: "",
      }, // 添加子分类表单
      addProjectClassifyForm_rules: {
        classifyName: [
          { required: true, message: "请输入分类名称", trigger: "blur" },
        ],
      }, // 添加子分类表单验证规则
      paginations: {
        page: 1, // 当前位于哪页
        total: 5, // 总数
        page_size: 12, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      }, //需要给分页组件传的信息
      ProjectBrandList: [], // 项目 品牌列表
      isProjectEdit: "", //编辑权限
      isProjectNew: "", //新增权限
    };
  },
  computed: {
    ProjectCategory: function () {
      if (!this.ruleForm_add.ProjectCategoryID) return "";
      const value = this.ruleForm_add.ProjectCategoryID.toString();
      const item = this.projectClassifyList.filter((item) => {
        if (item.Child.length) {
          return item.Child.some((item) => item.ID.toString() == value);
        }
      });
      return (
        item[0].Name +
        "/" +
        item[0].Child.find(
          (item) => item.ID == this.ruleForm_add.ProjectCategoryID
        ).Name
      );
    },
    ProjectBrand: function () {
      if (!this.ruleForm_add.ProjectBrandID) return "";
      const value = this.ruleForm_add.ProjectBrandID.toString();
      const item = this.ProjectBrandList.find((item) => item.ID == value);
      return item.Name;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.isProjectEdit = permission.permission(
        to.meta.Permission,
        "iBeauty-Goods-Project-Update"
      );
      vm.isProjectNew = permission.permission(
        to.meta.Permission,
        "iBeauty-Goods-Project-Create"
      );
      vm.getProjectBrandList();
    });
  },
  methods: {
    // 搜索
    getSearchResult() {
      var that = this;
      that.paginations.page = 1;
      that.getProjectList();
    },
    // 上下分页
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.getProjectList();
    },
    // 获取项目列表
    getProjectList: function () {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.paginations.page,
        Name: that.searchKey,
        ProjectCategoryID: that.classifyId,
        IsAllowSell: that.IsAllowSell,
        ProjectBrandID: that.ProjectBrandID,
      };
      API.getProjectList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.projectList = res.List;
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 新增
    showAddDialog: function () {
      var that = this;
      that.ruleForm_add = {
        IsAllowSell: true,
        IsAllowConsume: true,
        IsAllowLargess: false,
        ProjectBrandID: "",
        Memo: "",
        OriginalText: "",
        ImageList: [],
      };
      that.dialogTitle = "新增项目";
      that.activeName = "1";
      that.salesScopeData = [];
      that.consumptionData = [];
      that.defaultCheckedKeysSale = [];
      that.defaultExpandedKeysSale = [1];
      that.defaultCheckedKeysConsume = [];
      that.defaultExpandedKeysConsume = [1];
      Object.assign(that.salesScopeData, that.entityList);
      Object.assign(that.consumptionData, that.entityList);
      that.dialogVisible = true;
      that.whichDialog = "add";
      that.getValidProjectCategoryValid();
      that.getEntityList();
    },
    // 编辑/查看
    showEditDialog: function (row, type) {
      var that = this;
      that.whichDialog = "edit";
      that.ruleForm_add = Object.assign({}, row);
      that.dialogTitle = type == "edit" ? "编辑项目" : "项目详情";
      that.activeName = "1";
      that.dialogVisible = type == "edit" ? true : false;
      that.showDialogVisible = type == "show" ? true : false;
      that.salesScopeData = [];
      that.consumptionData = [];
      that.defaultCheckedKeysSale = [];
      that.defaultExpandedKeysSale = [1];
      that.defaultCheckedKeysConsume = [];
      that.defaultExpandedKeysConsume = [1];
      Object.assign(that.salesScopeData, that.entityList);
      Object.assign(that.consumptionData, that.entityList);
      that.ID = row.ID;
      that.getProjectSaleEntity();
      that.getProjectConsumeEntity();

      that.getValidProjectCategoryValid();
      that.getEntityList();
      that.getProjectDetails(that.ruleForm_add.ID);
    },
    // 表单确认事件
    submitForm(ruleForm_add) {
      var that = this;
      console.log(
        "🚀 ~ file: project.vue ~ line 625 ~ submitForm ~ ruleForm_add",
        that.ruleForm_add
      );
      if (that.whichDialog == "add") {
        that.$refs[ruleForm_add].validate((valid) => {
          if (valid) {
            that.ruleForm_add.ProjectSaleEntity =
              that.$refs.treeSale.getCheckedKeys();
            that.ruleForm_add.ProjectConsumeEntity =
              that.$refs.treeConsume.getCheckedKeys();
            that.createProject();
          }
        });
      } else {
        that.$refs[ruleForm_add].validate((valid) => {
          if (valid) {
            that.ruleForm_add.ProjectSaleEntity =
              that.$refs.treeSale.getCheckedKeys();
            that.ruleForm_add.ProjectConsumeEntity =
              that.$refs.treeConsume.getCheckedKeys();
            that.updateProject();
          }
        });
      }
    },
    // 创建项目
    createProject: function () {
      var that = this;
      that.updateLoading = true;
      that.loading = true;
      var params = that.ruleForm_add;
      params.Memo = that.$refs["goodsDetail"].getMemo();
      params.ImageList = Enumerable.from(that.ruleForm_add.ImageList)
        .select((val) => ({ ImageUrl: val.url }))
        .toArray();
      API.createProject(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("添加成功");
            that.dialogVisible = false;
            that.getProjectList();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
          that.updateLoading = false;
        });
    },
    // 更新项目
    updateProject: function () {
      var that = this;
      that.updateLoading = true;
      that.loading = true;
      var params = that.ruleForm_add;
      params.Memo = that.$refs["goodsDetail"].getMemo();
      params.ImageList = Enumerable.from(that.ruleForm_add.ImageList)
        .select((val) => ({ ImageUrl: val.url }))
        .toArray();

      API.updateProject(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogVisible = false;
            that.getProjectList();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
          that.updateLoading = false;
        });
    },
    // 获取项目销售范围
    getProjectSaleEntity: function () {
      var that = this;
      that.loading = true;
      var params = {
        ID: that.ID,
      };
      API.getProjectSaleEntity(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.defaultCheckedKeysSale = res.Data;
            that.defaultExpandedKeysSale = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取项目消耗范围
    getProjectConsumeEntity: function () {
      var that = this;
      that.loading = true;
      var params = {
        ID: that.ID,
      };
      API.getProjectConsumeEntity(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.defaultCheckedKeysConsume = res.Data;
            that.defaultExpandedKeysConsume = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 新增时获取权限范围
    getEntityList: function () {
      var that = this;
      that.loading = true;
      var params = {
        SearchKey: "",
        Active: "",
      };
      API.getEntityList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.entityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取项目分类
    getValidProjectCategoryValid: function () {
      var that = this;
      that.loading = true;
      var params = {
        Name: "",
        Active: "",
      };
      API.getValidProjectCategoryValid(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.projectClassifyList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 创建项目分类
    createProjectCategory: function () {
      var that = this;
      that.loading = true;
      var params = {
        ParentID: that.ParentID,
        Name: that.Name,
      };
      API.createProjectCategory(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("添加成功");
            if (that.openNumber == "1") {
              that.$refs["addProjectClassifyForm"].resetFields();
              that.addProjectClassifyDiaState = false;
            } else {
              that.$refs["addSubClassificationForm"].resetFields();
              that.addSubClassificationDiaState = false;
            }
            that.getValidProjectCategoryValid();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 添加子分类点击事件
    addSubClassification() {
      var that = this;
      that.addProjectClassifyDiaState = true;
    },
    // 添加子分类确认事件
    submitSuperiorClassify(formName, type) {
      var that = this;
      that.openNumber = type;
      if (type == "1") {
        that.$refs[formName].validate((valid) => {
          if (valid) {
            that.ParentID = that.addProjectClassifyForm.superiorClassify;
            that.Name = that.addProjectClassifyForm.classifyName;
            that.createProjectCategory();
          }
        });
      } else if (type == "2") {
        that.$refs[formName].validate((valid) => {
          if (valid) {
            that.ParentID = 0;
            that.Name = that.addSubClassificationForm.classificationName;
            that.createProjectCategory();
          }
        });
      }
    },
    // 添加上级分类点击事件
    addSuperiorClassification() {
      var that = this;
      that.addSubClassificationDiaState = true;
    },
    // 设置套餐卡包含的明细
    addSetDetails() {
      var that = this;
      that.setDetailsDialogVisible = true;
    },
    // 批量设置点击事件
    dropdownClick(val) {
      var that = this;
      that.selectType = val;
      if (val != "5" && val != "6") {
        that.setBatchDialogVisible = true;
      }
    },
    // 数据显示
    getProjectBrandList: function () {
      let that = this;
      var params = {
        Name: that.name,
        Active: that.active,
      };
      ProjectBrandAPI.getProjectBrandList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.ProjectBrandList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
    /**    */
    OriginalText_Change(val) {
      let that = this;
      that.ruleForm_add.OriginalText = val;
    },
    /**    */
    Memo_change(val) {
      let that = this;
      that.ruleForm_add.Memo = val;
    },
    /**    */
    ImageListChange(val) {
      let that = this;
      that.ruleForm_add.ImageList = val;
    },
    /**  请求详情  */
    getProjectDetails(ID) {
      let that = this;
      let param = {
        ID: ID,
      };
      API.getProjectDetails(param)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$set(that.ruleForm_add, "ImageList", []);
            that.$set(that.ruleForm_add, "Memo", "");
            that.$set(that.ruleForm_add, "OriginalText", "");
            that.$set(that.ruleForm_add, "ApplyNum", "");
            res.Data.ImageUrlList.forEach((item) => {
              utils.getCanvasBase64(item.ImageUrl).then((val) => {
                that.ruleForm_add.ImageList.push({ url: val });
              });
            });
            that.ruleForm_add.Memo = res.Data.Memo;
            that.ruleForm_add.OriginalText = res.Data.OriginalText;
            that.ruleForm_add.ApplyNum = res.Data.ApplyNum;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
  },
  mounted() {
    var that = this;
    that.getProjectList();
    that.getValidProjectCategoryValid();
    that.getEntityList();
    that.getProjectBrandList();
    that.isProjectEdit = permission.permission(
      that.$route.meta.Permission,
      "iBeauty-Goods-Project-Update"
    );
    that.isProjectNew = permission.permission(
      that.$route.meta.Permission,
      "iBeauty-Goods-Project-Create"
    );
  },
};
</script>

<style  lang="scss">
.Project {
  .el-input__inner {
    padding: 0 0 0 10px;
  }
  .showDialog {
    .el-form-item__label {
      color: #666;
    }
  }
  .el-scrollbar_height {
    height: 60vh;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
}
</style>
